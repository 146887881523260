import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  brandColours,
} from '../styles';
import Stars from './Stars';

const StyledReviewCard = styled.article``;

const StyledText = styled.p`
  padding: 16px 0;
  font-family: ${brandFonts.secondary};
  line-height: 1.9;
  border-bottom: 1px solid ${standardColours.lightGrey};

  ${minBreakpointQuery.large`
    padding-top: 20px;
    padding-bottom: 20px;
    ${fontSize(18)};
  `}
`;

const StyledName = styled.p`
  margin-top: 16px;
  font-family: ${brandFonts.secondary};

  ${minBreakpointQuery.large`
    margin-top: 20px;
    ${fontSize(18)};
  `}
`;

const StyledTime = styled.p`
  margin-top: 10px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.medium};
  ${fontSize(13)};

  ${minBreakpointQuery.large`
    margin-top: 12px;
    ${fontSize(14)};
  `}
`;

const ReviewCard = ({ rating, text, name, time, ...props }) => (
  <StyledReviewCard {...props}>
    {rating && <Stars rating={rating} />}
    <StyledText>{text}</StyledText>
    <StyledName>{name}</StyledName>
    {time && <StyledTime>Submitted {time}</StyledTime>}
  </StyledReviewCard>
);

export default ReviewCard;
