import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';
import ShopLocations from './ShopLocations';

const StyledShopInformation = styled.section`
  position: relative;
  ${sectionMargins(undefined, '80px')};

  &:before {
    ${minBreakpointQuery.xxlarge`
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 540px;
      width: 270px;
      background-color: ${brandColours.primary};
      border-radius: 0 270px 270px 0;
      opacity: 0.4;
      transform: translateX(-75%);
    `}
  }
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 80px;
  `}

  ${minBreakpointQuery.large`
    gap: 100px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 120px;
  `}
`;

const StyledContent = styled.div``;

const StyledImage = styled.img`
  ${maxBreakpointQuery.medium`
    width: 100%;
    max-height: 620px;
    object-fit: cover;
  `}

  ${minBreakpointQuery.medium`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `}
`;

const ShopInformation = ({ heading, text, image: { url, alt } }) => (
  <StyledShopInformation>
    <Container>
      <StyledInner>
        <StyledContent>
          <Heading>{heading}</Heading>
          <HtmlContent html={text} />
          <ShopLocations />
        </StyledContent>
        <StyledImage src={url} alt={alt} loading="lazy" />
      </StyledInner>
    </Container>
  </StyledShopInformation>
);

export default ShopInformation;
