import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import ImageCta from './ImageCta';

const StyledImageCtas = styled.section`
  ${minBreakpointQuery.smedium`
    display: flex;
  `}
`;

const ImageCtas = ({ items }) =>
  items.length > 0 && (
    <StyledImageCtas>
      {items.map((item, i) => (
        <ImageCta key={item.id} data={item} num={i} />
      ))}
    </StyledImageCtas>
  );

export default ImageCtas;
