import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Wave, Button } from './ui';

const StyledCtaStrip = styled.section`
  ${sectionPaddings(undefined, '85px')};
  text-align: center;
  background-color: ${standardColours.lighterGrey};
`;

const StyledHeading = styled.h2`
  font-weight: ${fontWeights.light};
  ${fontSize(18)};

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
  `}
`;

const StyledWave = styled(Wave)`
  margin: 30px 0 20px;
`;

const StyledText = styled.div`
  p {
    font-family: ${brandFonts.secondary};
    font-weight: ${fontWeights.regular};
    ${fluidFontSize(
      '26px',
      '42px',
      breakpointSizes.tiny,
      breakpointSizes.large
    )};
    line-height: 1.5;

    strong {
      color: ${brandColours.primary};
      font-weight: ${fontWeights.regular};
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  color: ${standardColours.darkGrey};

  svg {
    fill: ${standardColours.darkGrey};
    border-color: ${standardColours.darkGrey};
  }

  ${minBreakpointQuery.medium`
    margin-top: 30px;
  `}
`;

const CtaStrip = ({ heading, text, link }) => (
  <StyledCtaStrip>
    <Container narrow={true}>
      <StyledHeading>{heading}</StyledHeading>
      <StyledWave />
      <StyledText dangerouslySetInnerHTML={{ __html: text }} />
      <StyledButton alt={true} to={link.page.slug} linkData={link.page}>
        {link.text}
      </StyledButton>
    </Container>
  </StyledCtaStrip>
);

export default CtaStrip;
