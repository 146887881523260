import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
} from '../styles';
import { Container, Heading, Button } from './ui';
import ProductList from './ProductList';

const StyledFeaturedProducts = styled.section`
  position: relative;
  ${sectionMargins()};

  &:before {
    ${minBreakpointQuery.xxlarge`
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 540px;
      width: 270px;
      background-color: ${brandColours.primary};
      border-radius: 0 270px 270px 0;
      opacity: 0.4;
      transform: translateX(-75%);
    `}
  }
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.smedium`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  `}
`;

const StyledHeading = styled(Heading)`
  ${minBreakpointQuery.smedium`
    order: 1;
  `}
`;

const StyledList = styled(ProductList)`
  ${minBreakpointQuery.smedium`
    order: 3;
  `}
`;

const StyledButton = styled(Button)`
  ${maxBreakpointQuery.smedium`
    margin-top: 30px;
    width: 100%;
  `}

  ${minBreakpointQuery.smedium`
    order: 2;
  `}
`;

const FeaturedProducts = ({ heading, items }) => (
  <StyledFeaturedProducts>
    <Container>
      <StyledInner>
        {heading && <StyledHeading>{heading}</StyledHeading>}
        <StyledList items={items} />
        <StyledButton to="shop">Shop All Plants</StyledButton>
      </StyledInner>
    </Container>
  </StyledFeaturedProducts>
);

export default FeaturedProducts;
