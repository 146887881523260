import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Button } from './ui';
import Faq from './Faq';

const StyledFaqs = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.smedium`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  `}
`;

const StyledHeading = styled(Heading)`
  ${minBreakpointQuery.smedium`
    order: 1;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px;
  width: 100%;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    order: 3;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
    row-gap: 50px;
  `}
`;

const StyledButton = styled(Button)`
  ${maxBreakpointQuery.smedium`
    margin-top: 30px;
    width: 100%;
  `}

  ${minBreakpointQuery.smedium`
    order: 2;
  `}
`;

const Faqs = ({ heading, items, link }) =>
  items.length > 0 && (
    <StyledFaqs>
      <Container>
        <StyledInner>
          <StyledHeading>
            {heading || 'Frequently Asked Questions'}
          </StyledHeading>
          <StyledItems>
            {items.map(item => (
              <Faq key={item.id} data={item} />
            ))}
          </StyledItems>
          {link && <StyledButton to={link.slug}>View all FAQs</StyledButton>}
        </StyledInner>
      </Container>
    </StyledFaqs>
  );

export default Faqs;
