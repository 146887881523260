import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Autoplay, Grid, Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  fontSize,
  brandColours,
  standardTransition,
  visuallyHidden,
  maxBreakpointQuery,
} from '../styles';
import { Container, Heading, Slider } from './ui';
import FeaturedCategory from './FeaturedCategory';

const StyledFeaturedCategories = styled.section`
  --scrollbar-margin: 20px;
  ${sectionPaddings()};
  padding-bottom: 0;
  overflow: hidden;
  background-color: ${standardColours.lighterGrey};

  ${minBreakpointQuery.tiny`
    padding-bottom: 0;
  `}

  ${minBreakpointQuery.small`
    --scrollbar-margin: 30px;
  `}

  ${minBreakpointQuery.smedium`
   --scrollbar-margin: 40px;
  `}

  ${minBreakpointQuery.medium`
    --scrollbar-margin: 50px;
  `}

  ${minBreakpointQuery.mlarge`
    --scrollbar-margin: 60px;
  `}

  ${minBreakpointQuery.large`
    --scrollbar-margin: 70px;
  `}

  ${minBreakpointQuery.xlarge`
    --scrollbar-margin: 80px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding-bottom: 0;
  `}
`;

const StyledInner = styled.div`
  position: relative;
`;

const StyledHeader = styled.header`
  margin-bottom: 30px;

  ${minBreakpointQuery.tiny`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
    margin-bottom: 50px;
  `}
`;

const StyledText = styled.p`
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
  `}
`;

const StyledButton = styled.button`
  position: absolute;
  top: calc(50% - var(--scrollbar-margin) / 2);
  transform: translateY(-50%);
  z-index: 5;
  border: none;
  background: none;

  &:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 1px;
    border: 3px solid ${brandColours.primary};
    border-top: none;
    border-left: none;
    transition: ${standardTransition('border-color')};
    transform: ${({ prev }) =>
      prev
        ? `translateY(-50%) rotate(135deg)`
        : `translateY(-50%) rotate(-45deg)`};

    ${maxBreakpointQuery.smedium`
      ${visuallyHidden()};
    `}

    ${minBreakpointQuery.mlarge`
      height: 16px;
      width: 16px;
    `}

    ${minBreakpointQuery.xlarge`
      height: 20px;
      width: 20px;
    `}
  }

  ${({ prev }) => {
    if (prev) {
      return css`
        left: -25px;

        ${minBreakpointQuery.mlarge`
          left: -30px;
        `}

        ${minBreakpointQuery.xlarge`
          left: -35px;
        `}

        ${minBreakpointQuery.xxxlarge`
          left: -40px;
        `}
      `;
    } else {
      return css`
        right: -25px;
      `;
    }
  }}
`;

const StyledSlider = styled(Slider)`
  overflow: visible;
  clip-path: inset(0px -100vw 0px 0px);

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    width: auto;

    ${maxBreakpointQuery.tsmall`
      margin-top: 0 !important;
    `}
  }

  .swiper-wrapper {
    flex-direction: row !important;
  }
`;

const StyledScrollbar = styled.div`
  margin-top: var(--scrollbar-margin);
  height: 4px;
  max-width: 100%;
  background-color: ${standardColours.lightGrey};
  --swiper-scrollbar-size: 4px;
  --swiper-scrollbar-drag-bg-color: ${brandColours.primary};
`;

const FeaturedCategories = ({ heading, text }) => {
  const {
    allShopifyCollection: { nodes },
  } = useStaticQuery(graphql`
    query FeaturedCategoriesQuery {
      allShopifyCollection(sort: { fields: updatedAt, order: DESC }) {
        nodes {
          id
          title
          handle
          image {
            gatsbyImageData(width: 400, height: 460)
          }
        }
      }
    }
  `);
  const nextEl = useRef(null);
  const prevEl = useRef(null);
  const scrollbarEl = useRef(null);

  const sliderOptions = {
    modules: [Autoplay, Grid, Navigation, Scrollbar],
    slidesPerView: 1,
    spaceBetween: 30,
    grid: { rows: 2 },
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    speed: 1000,
    breakpoints: {
      [breakpointSizes.tsmall]: { slidesPerView: 2, grid: { rows: 1 } },
      [breakpointSizes.medium]: { slidesPerView: 3, grid: { rows: 1 } },
    },
    onInit: swiper => {
      swiper.params.navigation.prevEl = prevEl.current;
      swiper.params.navigation.nextEl = nextEl.current;
      swiper.navigation.init();
      swiper.navigation.update();
      swiper.params.scrollbar.el = scrollbarEl.current;
      swiper.params.scrollbar.draggable = true;
      swiper.scrollbar.init();
    },
  };

  return (
    <StyledFeaturedCategories>
      <Container>
        {heading && (
          <StyledHeader>
            <Heading>{heading}</Heading>
            {text && <StyledText>{text}</StyledText>}
          </StyledHeader>
        )}
        <StyledInner>
          <StyledButton ref={prevEl} prev={true} aria-label="Previous" />
          <StyledButton ref={nextEl} aria-label="Next" />
          <StyledSlider {...sliderOptions}>
            {nodes.map(node => (
              <FeaturedCategory key={node.id} data={node} />
            ))}
          </StyledSlider>
          <StyledScrollbar ref={scrollbarEl} />
        </StyledInner>
      </Container>
    </StyledFeaturedCategories>
  );
};

export default FeaturedCategories;
