import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { Link } from './ui';

const StyledFeaturedCategory = styled.article`
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid ${standardColours.lightGrey};

  ${maxBreakpointQuery.tsmall`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.small`
    padding-bottom: 30px;
  `}
`;

const StyledLink = styled(Link)`
  display: block;
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.tsmall`
    height: 200px;
  `}
`;

const StyledHeading = styled.h3`
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 20px 30px 0 0;
  max-width: calc(100% - 20px);
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fontSize(22)};
  background-color: ${standardColours.lighterGrey};

  ${minBreakpointQuery.small`
    bottom: 30px;
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-right: 40px;
    ${fontSize(26)};
  `}
`;

const FeaturedCategory = ({ data: { title, handle, image }, ...props }) => {
  const urlPath = `shop/${handle}`;

  return (
    <StyledFeaturedCategory {...props}>
      {image && (
        <StyledLink to={urlPath}>
          <StyledImage image={image.gatsbyImageData} alt={title} />
        </StyledLink>
      )}
      <StyledHeading>
        <Link to={urlPath}>{title}</Link>
      </StyledHeading>
    </StyledFeaturedCategory>
  );
};

export default FeaturedCategory;
