import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
} from '../styles';
import { Heading, Button } from './ui';

const StyledImageCta = styled.article`
  padding: 40px 30px;
  width: 100%;
  background-color: ${({ num }) =>
    num % 2 ? brandColours.secondary : brandColours.primary};
`;

const StyledInner = styled.div`
  position: relative;

  ${minBreakpointQuery.smedium`
    max-width: 580px;
    
    ${({ num }) => {
      if (num % 2) {
        return css`
          margin-right: auto;
        `;
      } else {
        return css`
          margin-left: auto;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.mlarge`
    height: 100%;
  `}
`;

const StyledImageWrapper = styled.div`
  ${maxBreakpointQuery.mlarge`
    display: none;
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 250px;
    background-color: ${standardColours.black};
    transform: translateX(-50%);
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${minBreakpointQuery.mlarge`
    height: 100%;
    opacity: 0.6;
  `}
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.mlarge`
    position: relative;
    padding-top: 80px;
    max-width: 380px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 100px;
  `}
`;

const StyledText = styled.p`
  margin: 25px 0;
  font-weight: ${fontWeights.medium};
  color: ${standardColours.white};
  line-height: 1.7;
  white-space: break-spaces;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 35px;
    margin-bottom: 35px;
  `}
`;

const ImageCta = ({
  data: {
    heading,
    text,
    image: { gatsbyImageData, alt },
    link,
  },
  num,
}) => (
  <StyledImageCta num={num}>
    <StyledInner num={num}>
      <StyledImageWrapper>
        <StyledImage image={gatsbyImageData} alt={alt} />
      </StyledImageWrapper>
      <StyledContent>
        <Heading colour={standardColours.white} removeWave={true}>
          {heading}
        </Heading>
        <StyledText>{text}</StyledText>
        <Button alt={true} to={link.page.slug} linkData={link.page}>
          {link.text}
        </Button>
      </StyledContent>
    </StyledInner>
  </StyledImageCta>
);

export default ImageCta;
