import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';

const StyledFaq = styled.div`
  border-bottom: 1px solid ${standardColours.lightGrey};
`;

const StyledHeading = styled.h3`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize('22px', '28px', breakpointSizes.tiny, breakpointSizes.large)};
`;

const StyledText = styled.p`
  margin: 20px 0 30px;
  line-height: 1.7;

  ${minBreakpointQuery.small`
    ${fontSize(17)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}
`;

const Faq = ({ data: { heading, text } }) => (
  <StyledFaq>
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
  </StyledFaq>
);

export default Faq;
