import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import FeaturedLogos from '../components/FeaturedLogos';
import FeaturedCategories from '../components/FeaturedCategories';
import CtaStrip from '../components/CtaStrip';
import FeaturedProducts from '../components/FeaturedProducts';
import CompanyReviews from '../components/CompanyReviews';
import IconCtas from '../components/IconCtas';
import ShopInformation from '../components/ShopInformation';
import ImageCtas from '../components/ImageCtas';
import Faqs from '../components/Faqs';
import FeaturedBlogPosts from '../components/FeaturedBlogPosts';

const HomePageTemplate = ({
  data: {
    datoCmsHome: {
      seoMetaTags,
      bannerImage,
      bannerHeading,
      bannerLinks,
      productCategoriesHeading,
      productCategoriesText,
      ctaStripHeading,
      ctaStripText,
      ctaStripLink,
      featuredProductsHeading,
      companyReviewsHeading,
      shopInformationHeading,
      shopInformationText,
      shopInformationImage,
      imageCtas,
      faqsHeading,
      faqs,
      faqsLinkPage,
      featuredBlogsHeading,
    },
    allShopifyProduct: { nodes: featuredProducts },
  },
}) => (
  <Layout isHomepage={true}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        image={bannerImage}
        heading={bannerHeading}
        links={bannerLinks}
        isHomepage={true}
      />
      <FeaturedLogos isHomepage={true} />
      <FeaturedCategories
        heading={productCategoriesHeading}
        text={productCategoriesText}
      />
      <CtaStrip
        heading={ctaStripHeading}
        text={ctaStripText}
        link={ctaStripLink}
      />
      <FeaturedProducts
        heading={featuredProductsHeading}
        items={featuredProducts}
      />
      <CompanyReviews heading={companyReviewsHeading} />
      <IconCtas />
      <ShopInformation
        heading={shopInformationHeading}
        text={shopInformationText}
        image={shopInformationImage}
      />
      <ImageCtas items={imageCtas} />
      <Faqs heading={faqsHeading} items={faqs} link={faqsLinkPage} />
      <FeaturedBlogPosts heading={featuredBlogsHeading} />
    </main>
  </Layout>
);

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery($featuredProducts: [String]) {
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bannerImage {
        gatsbyImageData(width: 1400, height: 600, layout: FULL_WIDTH)
        alt
      }
      bannerHeading
      bannerLinks {
        id
        text
        page {
          ...LinkFragment
        }
      }
      productCategoriesHeading
      productCategoriesText
      ctaStripHeading
      ctaStripText
      ctaStripLink {
        text
        page {
          ...LinkFragment
        }
      }
      featuredProductsHeading
      companyReviewsHeading
      shopInformationHeading
      shopInformationText
      shopInformationImage {
        url
        alt
      }
      imageCtas {
        id
        heading
        text
        image {
          gatsbyImageData(width: 250, height: 400)
          alt
        }
        link {
          text
          page {
            ...LinkFragment
          }
        }
      }
      faqsHeading
      faqs {
        id
        heading
        text
      }
      faqsLinkPage {
        ...LinkFragment
      }
      featuredBlogsHeading
    }
    allShopifyProduct(filter: { handle: { in: $featuredProducts } }) {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default HomePageTemplate;
