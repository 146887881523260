import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { Container, Heading, Slider, Svg } from './ui';
import ReviewCard from './ReviewCard';
import arrowIcon from '../images/arrow-icon.svg';
import googleReviewLogo from '../images/google-reviews.svg';

const StyledCompanyReviews = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledArrows = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrow = styled.button`
  margin: 5px;
  padding: 10px;
  height: 38px;
  width: 38px;
  background: none;
  border: 1px solid ${standardColours.darkGrey};
  border-radius: 50%;
  cursor: pointer;
  ${standardTransition('background-color')};

  ${minBreakpointQuery.smedium`
    margin: 10px;
  `}

  ${minBreakpointQuery.large`
    margin: 15px;
  `}

  svg {
    height: 16px;
    width: 16px;
    fill: ${standardColours.darkGrey};
    ${standardTransition('fill')};
  }

  span {
    ${visuallyHidden()};
  }

  &:hover {
    background-color: ${brandColours.primary};

    svg {
      fill: ${standardColours.white};
    }
  }

  &:first-child {
    transform: rotate(180deg);
  }
`;

const StyledSlider = styled(Slider)`
  margin: 0 -30px;

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    width: auto;
  }
`;

const StyledItem = styled(ReviewCard)`
  padding: 0 30px;

  ${minBreakpointQuery.large`
    padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

const StyledLogo = styled(Svg)`
  display: block;
  margin: 20px auto 0;
  height: 54px;
  width: 120px;

  ${minBreakpointQuery.smedium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const CompanyReviews = ({ heading }) => {
  const {
    allGoogleReview: { nodes },
  } = useStaticQuery(graphql`
    query CompanyReviewsQuery {
      allGoogleReview {
        nodes {
          id
          rating
          text
          author_name
          relative_time_description
        }
      }
    }
  `);

  const nextEl = useRef(null);
  const prevEl = useRef(null);

  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      [breakpointSizes.small]: { slidesPerView: 2 },
      [breakpointSizes.mlarge]: { slidesPerView: 3 },
    },
    onInit: swiper => {
      swiper.params.navigation.prevEl = prevEl.current;
      swiper.params.navigation.nextEl = nextEl.current;
      swiper.navigation.init();
      swiper.navigation.update();
    },
  };

  return (
    nodes.length > 0 && (
      <StyledCompanyReviews>
        <Container>
          <StyledHeader>
            <Heading>{heading}</Heading>
            <StyledArrows>
              <StyledArrow ref={prevEl} alt={true} aria-label="Previous">
                <Svg image={arrowIcon} />
                <span>Previous</span>
              </StyledArrow>
              <StyledArrow ref={nextEl} alt={true} aria-label="Next">
                <Svg image={arrowIcon} />
                <span>Next</span>
              </StyledArrow>
            </StyledArrows>
          </StyledHeader>
          <StyledSlider {...sliderOptions}>
            {nodes.map(
              ({
                id,
                rating,
                text,
                author_name,
                relative_time_description,
              }) => (
                <StyledItem
                  key={id}
                  rating={rating}
                  text={text}
                  name={author_name}
                  time={relative_time_description}
                />
              )
            )}
          </StyledSlider>
          <StyledLogo image={googleReviewLogo} />
        </Container>
      </StyledCompanyReviews>
    )
  );
};

export default CompanyReviews;
